import React from "react";

// Customizable Area Start
import "../../email-account-registration/css/verifyCode.css"
import {
  Container,
  Box,
  Button,
  Input,
  Typography,
  InputAdornment,
  IconButton,
  Checkbox,
  Snackbar,

} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import { googleIcon, Logo, verifyCodePoster } from "./assets";
import OTPInput from '../../email-account-registration/src/OTPInput.web'
// Customizable Area End

import ForgotPasswordController, {
  Props,
} from "./ForgotPasswordController";


const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class VerifyOTP extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
  }
// Customizable Area Start

verificationCall = (otpArry:any) => {
  let otpString = '';
  for(let i=0; i<otpArry.length; i++){
    otpString += otpArry[i];
  }
  this.validateOTP(otpString);
}
async componentDidMount() {
  super.componentDidMount();
  const storedEmail = localStorage.getItem("windUserEmail");
  if (storedEmail) {
    this.setState({ emailValue: storedEmail });
  } else {
    console.error("No email found in navigation or localStorage.");
  }
}
// Customizable Area End
  

  render() {
    
    return (
      // Required for all blocks
      // Customizable Area Start
      <div className="container">
      <div className="header">
        <img src={Logo} alt="" data-test-id="imageLogo" className="logoImg" onClick={() => {this.props.navigation.navigate("Home")}}/>
      </div>
      <div className="bodyContainer">
        <img src={verifyCodePoster} alt="" style={{marginBottom:"15px"}}/>
        <Typography style={{
          fontWeight: "bold",
          color: "#336699",
          marginBottom:"10px"
        }} variant="h6">Verification</Typography>
        <Typography variant="body2" style={{width:"280px", marginBottom:"0px", textAlign:"center"}} >
          Enter the verification code we just send 
          your email address.
        </Typography>
        <OTPInput verificationCall={this.verificationCall} style={{width:"300px"}}/>
        <p style={{fontSize:"12px"}}>If you didn't receive a code! <p data-test-id="resendOTPId" onClick={() => {
          this.resendOTPCall();
        }} className="resendtext">Resend</p></p>
        {/* {this.state.isError && <Typography style={{color: "#006633"}} variant="body2">{this.state.errorMessage}</Typography>} */}
        <Snackbar data-test-id="snackError" open={this.state.isError} autoHideDuration={6000} onClose={() => this.setState({ isError: false })}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              <Alert severity="error">{this.state.errorMessage}</Alert>
            </Snackbar>
      </div>
    </div>
    // Customizable Area End
    );
  }
}
